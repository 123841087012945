var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dates jobFilterSection jobFilterSection--dates" },
    [
      _c(
        "label",
        [
          _c("icon", {
            staticClass: "jobFilter__icon",
            attrs: { icon: "calendar" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("jobFilter.form.dates.label")) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("label", { attrs: { for: _vm.id + "-start" } }, [
            _vm._v(_vm._s(_vm.$t("jobFilter.form.dates.start.label"))),
          ]),
          _vm._v(" "),
          _c("input-field", {
            attrs: {
              id: _vm.id + "-start",
              placeholder: _vm.$t("jobFilter.form.dates.placeholder"),
              type: "date",
            },
            on: { input: _vm.emitDemandFilterEvent },
            scopedSlots: _vm._u([
              {
                key: "beforeClearIcon",
                fn: function () {
                  return [
                    _vm.isStartEmpty
                      ? _c("icon", {
                          staticClass: "dates__pickerIcon",
                          attrs: { icon: "calendar" },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.demand.start,
              callback: function ($$v) {
                _vm.$set(_vm.demand, "start", $$v)
              },
              expression: "demand.start",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("label", { attrs: { for: _vm.id + "-end" } }, [
            _vm._v(_vm._s(_vm.$t("jobFilter.form.dates.end.label"))),
          ]),
          _vm._v(" "),
          _c("input-field", {
            attrs: {
              id: _vm.id + "-end",
              type: "date",
              placeholder: _vm.$t("jobFilter.form.dates.placeholder"),
            },
            on: { input: _vm.emitDemandFilterEvent },
            scopedSlots: _vm._u([
              {
                key: "beforeClearIcon",
                fn: function () {
                  return [
                    _vm.isEndEmpty
                      ? _c("icon", {
                          staticClass: "dates__pickerIcon",
                          attrs: { icon: "calendar" },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.demand.end,
              callback: function ($$v) {
                _vm.$set(_vm.demand, "end", $$v)
              },
              expression: "demand.end",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }