



































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {JobService} from '../../../../../Service/JobService';
import Checkbox from '../../../../Form/Checkbox/Checkbox.vue';
import MultiSelectBox from '../../../../Form/MultiSelectBox/MultiSelectBox.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../JobFilterMixin';

export default {
    name: 'JobOccupationFilter',
    components: {MultiSelectBox, Checkbox, Icon},
    mixins: [JobFilterMixin],
    computed: {
        items(): Array<{ label: string, value: string }>
        {
            return JobService.jobOccupations;
        }
    },
    methods: {
        onInput(val)
        {
            this.demand.occupation = isEmpty(val) ? null : val;
            this.emitDemandFilterEvent();
        }
    }
};
