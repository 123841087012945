var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location jobFilterSection jobFilterSection--location" },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _c("icon", {
            staticClass: "jobFilter__icon",
            attrs: { icon: "mapPin" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("jobFilter.form.location.label")) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("prefix"),
      _vm._v(" "),
      _c("select-box", {
        attrs: {
          id: _vm.id,
          placeholder: _vm.$t("jobFilter.form.location.placeholder"),
          itemText: "address",
          itemValue: "address",
          items: _vm.state.items,
          "show-spinner": _vm.state.showSpinner,
          "error-message": _vm.error,
          clearable: "",
          readonly: _vm.readonly,
        },
        on: { search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "input-end",
            fn: function () {
              return [_vm._v(" ")]
            },
            proxy: true,
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t(_vm.noDataLabel)) +
                    "\n        "
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.valueProxy,
          callback: function ($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }