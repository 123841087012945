













































import {forEach} from '@labor-digital/helferlein';
import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {GeoCodeLocation} from '../../../../../Interface/GeoCodeLocation';
import {BranchAndGeoLocationService} from '../../../../../Service/BranchAndGeoLocationService';
import {JobService} from '../../../../../Service/JobService';
import SelectBox from '../../../../Form/SelectBox/SelectBox.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../JobFilterMixin';

export default {
    name: 'JobLocationFilter',
    components: {SelectBox, Icon},
    mixins: [JobFilterMixin],
    props: {
        error: String,
        readonly: false
    },
    computed: {
        valueProxy: {
            get()
            {
                return this.demand.location;
            },
            set(v)
            {
                if (v == null) {
                    this.demand.location = null;
                } else {
                    forEach(this.state.items, (item: GeoCodeLocation) => {
                        if (item.address === v) {
                            if (this.demand.location !== v) {
                                this.demand.location = item;
                                BranchAndGeoLocationService.setCurrentLocation(item);
                                this.emitDemandFilterEvent();
                            }
                            return false;
                        }
                    });

                    if (this.demand.sorting !== 'distance') {
                        this.demand.sorting = 'distance';
                    }
                }
            }
        },
        state()
        {
            return JobService.state.location;
        },
        noDataLabel(): string
        {
            if (this.state.showSpinner) {
                return 'jobFilter.form.location.running';
            }
            return this.state.tooShort ?
                'jobFilter.form.location.tooShort' :
                'jobFilter.form.location.noResults';
        }
    },
    methods: {
        /**
         * An internal helper to resolve the auto-complete location items for the given query string
         * It will automatically update all props to show the spinner or error messages
         * @param query
         */
        onSearch(query: string): Promise<any>
        {
            // Validate the query length
            query = (query + '').trim();
            this.state.tooShort = query.length < 3;
            if (this.state.tooShort) {
                return Promise.resolve();
            }

            // Start the spinner
            this.state.showSpinner = true;

            // Require the location results
            return debouncePromise('jobSearchLocation',
                () => JobService.context.axios.get('/location/geocode/' + query), 300, true)
                .then(response => {
                    this.state.items = response.data;
                    this.state.showSpinner = false;
                });
        }
    }
};
