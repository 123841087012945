








































import {isEmpty, map} from '@labor-digital/helferlein';
import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {PreparedListItem} from '@labor-digital/vuetiful/src/Utils/Mixin/ListItemMixin';
import {JobService} from '../../../../../Service/JobService';
import FormTypeahead from '../../../../Form/FormTypeahead/FormTypeahead.vue';
import SelectBox from '../../../../Form/SelectBox/SelectBox.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../JobFilterMixin';

export default {
    name: 'JobQueryFilter',
    components: {FormTypeahead, Icon, SelectBox},
    mixins: [JobFilterMixin],
    data()
    {
        return {
            query: JobService.demand.query
        };
    },
    computed: {
        valueProxy: {
            get()
            {
                if (!isEmpty(this.state.simulated)) {
                    return this.state.simulated;
                }
                return this.query;
            },
            set(v)
            {
                if (v === this.state.simulated) {
                    return;
                }

                this.query = v;
                if (v === '') {
                    this.updateDemand();
                }
            }
        },
        state()
        {
            return JobService.state.query;
        },
        noDataLabel(): string
        {
            if (this.state.showSpinner) {
                return 'jobFilter.form.query.running';
            }
            return this.state.tooShort ?
                'jobFilter.form.query.tooShort' :
                'jobFilter.form.query.noResults';
        }
    },
    methods: {
        resolveSearch(query: string): Promise<Array<PreparedListItem>>
        {
            // Validate the query length
            query = (
                query + ''
            ).trim();
            this.state.tooShort = query.length < 3;
            if (this.state.tooShort) {
                return Promise.resolve([]);
            }

            // Start the spinner
            this.state.showSpinner = true;

            // Require the query results
            return debouncePromise('jobSearchQuery',
                () => JobService.context.resourceApi.getAdditional('job', 'autocomplete/' + query), 300, true)
                .then(response => {
                    this.state.showSpinner = false;
                    return map(response, i => i.title);
                });
        },
        /**
         * Propagates the query update when a trigger occurred
         */
        updateDemand(): void
        {
            if (this.demand.query === this.query) {
                return;
            }
            this.demand.query = this.query;
            this.emitDemandFilterEvent();
        },
        onKeyUp(e: KeyboardEvent): void
        {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                this.updateDemand();
            }
        },
        onInputClear()
        {
            JobService.leaveSerpsPage(true, true);
        }
    },
    watch: {
        'demand.query'(n)
        {
            this.query = n;
        }
    }
};
