var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "employmentType jobFilterSection jobFilterSection--contractType",
    },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _c("icon", {
            staticClass: "jobFilter__icon",
            attrs: { icon: "briefcase" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("jobFilter.form.contractType.label")) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("checkbox", {
        attrs: { id: _vm.id, items: _vm.items },
        on: { input: _vm.emitDemandFilterEvent },
        model: {
          value: _vm.demand.contractType,
          callback: function ($$v) {
            _vm.$set(_vm.demand, "contractType", $$v)
          },
          expression: "demand.contractType",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }