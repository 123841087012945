var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "occupation jobFilterSection jobFilterSection--occupation" },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _c("icon", {
            staticClass: "jobFilter__icon",
            attrs: { icon: "briefcase" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("jobFilter.form.occupation.label")) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("multi-select-box", {
        attrs: {
          value: _vm.demand.occupation,
          id: _vm.id,
          items: _vm.items,
          placeholder: _vm.$t("jobFilter.form.occupation.placeholder"),
          "multi-select-alt-placeholder": _vm.$t(
            "jobFilter.form.occupation.altPlaceholder"
          ),
        },
        on: { input: _vm.onInput },
        nativeOn: {
          click: function ($event) {
            return function () {}.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }