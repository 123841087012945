var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "job jobFilterSection jobFilterSection--jobQuery" },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _c("icon", {
            staticClass: "jobFilter__icon",
            attrs: { icon: "briefcase" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("jobFilter.form.query.label")) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("prefix"),
      _vm._v(" "),
      _c("FormTypeahead", {
        attrs: {
          "no-loader": "",
          id: _vm.id,
          placeholder: _vm.$t("jobFilter.form.query.placeholder"),
          "show-spinner": _vm.state.showSpinner,
          "list-item-resolver": _vm.resolveSearch,
        },
        on: {
          clear: _vm.onInputClear,
          blur: _vm.updateDemand,
          select: _vm.updateDemand,
        },
        nativeOn: {
          keyup: function ($event) {
            return _vm.onKeyUp.apply(null, arguments)
          },
        },
        model: {
          value: _vm.valueProxy,
          callback: function ($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }