


















































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import InputField from '../../../../Form/InputField/InputField.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../JobFilterMixin';

export default {
    name: 'JobDateFilter',
    components: {InputField, Icon},
    mixins: [JobFilterMixin],
    computed: {
        isStartEmpty(): boolean
        {
            return isEmpty(this.demand.start);
        },
        isEndEmpty(): boolean
        {
            return isEmpty(this.demand.end);
        }
    }
};
