































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {JobService} from '../../../../../Service/JobService';
import Checkbox from '../../../../Form/Checkbox/Checkbox.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../JobFilterMixin';

export default {
    name: 'JobEmploymentTypeFilter',
    components: {Checkbox, Icon},
    mixins: [JobFilterMixin],
    computed: {
        items()
        {
            return JobService.employmentTypes.filter(type => {
                return !isEmpty(type.label);
            });
            ;
        }
    }
};
